import React from "react";
import {
    FaArrowUp
  } from "react-icons/fa";
function BackToTop(){

    window.addEventListener('scroll',()=>{
        const backtop = document.getElementById('backtop');
        if(window.scrollY >= 100){
            backtop.style.display = 'block';
        }
        else{
            backtop.style.display = 'none';
        }
    });

    let backToTop = ()=>{
        window.scrollTo(0,0);
    }

    return(
        <>
            <button id="backtop" style={{display:"none", position:"fixed", right:"2%", top:"90%", textAlign:"center", backgroundColor:"black",}} onClick={()=>backToTop()} className="glow-on-hover btn">
            &nbsp;<FaArrowUp/>
            </button>
        </>
    )
}

export default BackToTop;