import { TbBrandNextjs, TbBrandMysql, TbPlanet } from "react-icons/tb";
import { SiCsharp } from "react-icons/si";
import {
    FaHtml5,
    FaCss3,
    FaJava,
    FaFigma,
    FaPython,
    FaNodeJs,
    FaReact,
    FaPhp,
    FaSdCard
} from "react-icons/fa";

export const SkillsSchema = {
    Frontend: [
        {
            name: "CSS",
            icon: <FaCss3 />,
            skill: 90,
            color: "blue"
        },
        {
            name: "ReactJS",
            icon: <FaReact />,
            skill: 90,
            color: "#87CEEB"
        },
        {
            name: "NextJS",
            icon: <TbBrandNextjs />,
            skill: 80,
            color: "black"
        },
        {
            name: "HTML",
            icon: <FaHtml5 />,
            skill: 95,
            color: "red"
        },
    ],
    Backend: [
        {
            name: "Node Js",
            icon: <FaNodeJs />,
            skill: 90,
            color: "#75f542"
        },
        {
            name: "Java",
            icon: <FaJava />,
            skill: 90,
            color: "#425df5"
        },
        {
            name: ".Net",
            icon: <SiCsharp />,
            skill: 70,
            color: "#95478e"
        },
        {
            name: "PHP",
            icon: <FaPhp />,
            skill: 75,
            color: "black"
        },
    ],
    Database: [
        {
            name: "Firebase",
            icon: <FaSdCard />,
            skill: 80,
            color: "yellow"
        },
        {
            name: "MySql",
            icon: <TbBrandMysql />,
            skill: 90,
            color: "#425df5"
        },
        {
            name: "Azure CosmosDB",
            icon: <TbPlanet />,
            skill: 70,
            color: "#95478e"
        }
    ],
    Other: [
        {
            name: "C",
            icon: "",
            skill: 80,
            color: "yellow"
        },
        {
            name: "C++",
            icon: "",
            skill: 90,
            color: "#425df5"
        },
        {
            name: "Python",
            icon: <FaPython />,
            skill: 60,
            color: "blue"
        },
        {
            name: "Figma",
            icon: <FaFigma />,
            skill: 60,
            color: "#f5bc42"
        }
    ]
}