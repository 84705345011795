import React from 'react';
import './stylesheet/projects.css'
import sandesh from './assets/projects/sandesh_main.PNG';
import khatabook from './assets/projects/khatabook.PNG';
import rojgar from './assets/projects/rojgar.PNG';
import kaushalya from './assets/projects/kaushalya.PNG';
import uploadme from './assets/projects/uploadme.PNG';
import goxo from './assets/projects/goxo.PNG';
import kitacademy from './assets/projects/kitacademy.PNG';
import encrypt from './assets/projects/encrypt.PNG';
import savaari from './assets/projects/savaari.png';
import foodforall from './assets/projects/foodforall.png';
import cli from './assets/projects/cli.PNG';
import ivedics from './assets/projects/ivedicsimg.PNG';
import shorturlme from './assets/projects/shorturlme.PNG';
import { Link } from 'react-router-dom';
import BackToTop from './backtotop';


function Projects(){
    window.speechSynthesis.cancel();
    window.scrollTo(0, 0);
    let moveAsidesMenu = (para = "home_menu")=>{
        document.getElementById('home_menu').classList.remove('curr_page_on_menu');
        document.getElementById('skills_menu').classList.remove('curr_page_on_menu');
        document.getElementById('projects_menu').classList.remove('curr_page_on_menu');
        document.getElementById('aboutme_menu').classList.remove('curr_page_on_menu');
        document.getElementById('contact_menu').classList.remove('curr_page_on_menu');
        // document.getElementById('side_contact').classList.remove('curr_page_on_menu');
        document.getElementById(para).classList.add('curr_page_on_menu');
    }
    let filterProject = (filt) => {
        const ele = document.getElementsByClassName(filt);
        for(let i=0;i<ele.length;i++){
            ele[i].style.display = 'block';
        }
        if(filt === "website"){
            for(let i=0;i<document.getElementsByClassName('android').length;i++){
                document.getElementsByClassName('android')[i].style.display = 'none';
            }
            document.getElementsByClassName('commercial')[0].style.display = 'none';
            document.getElementsByClassName('commercial')[1].style.display = 'none';
            document.getElementsByClassName('projects_category')[0].classList.add('widthwebsites');
            document.getElementsByClassName('projects_category')[0].classList.remove('widthCLI');
            document.getElementsByClassName('projects_category')[0].classList.remove('widthAndroid');
        }
        else if(filt === "android"){
            document.getElementsByClassName('commercial')[0].style.display = 'none';
            document.getElementsByClassName('commercial')[1].style.display = 'none';
            for(let i=0;i<document.getElementsByClassName('website').length;i++){
                document.getElementsByClassName('website')[i].style.display = 'none';
            }
            document.getElementsByClassName('projects_category')[0].classList.add('widthAndroid');
            document.getElementsByClassName('projects_category')[0].classList.remove('widthCLI');
            document.getElementsByClassName('projects_category')[0].classList.remove('widthwebsites');
        }
        else{
            document.getElementsByClassName('commercial')[0].style.display = 'block';
            document.getElementsByClassName('commercial')[1].style.display = 'block';
            for(let i=0;i<document.getElementsByClassName('website').length;i++){
                document.getElementsByClassName('website')[i].style.display = 'none';
            }
            for(let i=0;i<document.getElementsByClassName('android').length;i++){
                document.getElementsByClassName('android')[i].style.display = 'none';
            }
            document.getElementsByClassName('projects_category')[0].classList.add('widthCLI');
            document.getElementsByClassName('projects_category')[0].classList.remove('widthAndroid');
            document.getElementsByClassName('projects_category')[0].classList.remove('widthwebsites');
        }
    }
    return(
        <>
            <section className="projects_section">
                <div className="project_title">
                    <p>Projects</p>
                </div>

                <div className="projects_main">
                    <div className="btn-main-project">
                        <button onClick={()=>filterProject("website")} className="glow-on-hover btn">Websites</button>
                        <button onClick={()=>filterProject("android")} className="glow-on-hover btn">Android Apps</button>
                        <button onClick={()=>filterProject("commercial")} className="glow-on-hover btn">Commercial</button>
                    </div>

                    <div className="projects_category">
                    <div className="project glow-on-hover android">
                            <div className="project_image">
                                <img src={savaari} alt="savaari bus tracker" />
                            </div>
                            <div className="title">
                                <strong>Savaari - Bus Tracking App</strong>
                            </div>
                            <div className="project_info">
                                <p>Savaari is bus tracking android application specially built for kolhapur muncipal transportation(KMT). Using this application passengers are able to track kmt buses with approximate time of arrival. This application also helps bus drivers to manage their days schedule.</p>
                            </div>
                            <a href="https://www.canva.com/design/DAFDfoGb94w/BgbqYURSNzLQK47XAJj1Gg/view?utm_content=DAFDfoGb94w&utm_campaign=designshare&utm_medium=link2&utm_source=sharebutton#5" target="blank"> <button className="glow-on-hover btn view_project">View</button></a>
                        </div>
                        <div className="project glow-on-hover android">
                            <div className="project_image">
                                <img src={foodforall} alt="Food for All" />
                            </div>
                            <div className="title">
                                <strong>Food For All - Donation App</strong>
                            </div>
                            <div className="project_info">
                                <p>Food For All is an application that connects the merchant, who works in supply centers and has to throw away unsold food everyday, and NGOs that fight hunger and help to feed hungry people before the food becomes unfit for human consumption.</p>
                            </div>
                            <a href="https://www.canva.com/design/DAE9gZeyZFI/0WSNKz9ihCLTv02F8yF35A/view?utm_content=DAE9gZeyZFI&utm_campaign=designshare&utm_medium=link2&utm_source=sharebutton" target="blank"> <button className="glow-on-hover btn view_project">View</button></a>
                        </div>

                        <div className="project glow-on-hover website">
                            <div className="project_image">
                                <img src={rojgar} alt="Sandesh Chat Application" />
                            </div>
                            <div className="title">
                                <strong>Rojgar - Job Boarding</strong>
                            </div>
                            <div className="project_info">
                                <p>Job Boarding is a website that specifically deals with employment, careers and internships. It is a platform that supports job opportunities for freshers as well as experienced.</p>
                            </div>
                            <a href="http://rojgarapp.tk/" target="blank"> <button className="glow-on-hover btn view_project">View</button></a>
                        </div>

                        <div className="project glow-on-hover website">
                            <div className="project_image">
                                <img src={kaushalya} alt="Kaushalya Web Application" />
                            </div>
                            <div className="title">
                                <strong>Kaushalya - Skilled Worker Platform</strong>
                            </div>
                            <div className="project_info">
                                <p>Koushalya encourage for jobs and employbility. There are a lot of workers in the informal sector such as carpenters, plumbers, electricians, painter, etc. Kaushalya provide comprehensive platform for skilled people and the society which act like a problem solver bridge.</p>
                            </div>
                            <a href="https://kaushalya.ashwith.in/" target="blank"> <button className="glow-on-hover btn view_project">View</button></a>
                        </div>

                        <div className="project glow-on-hover website">
                            <div className="project_image">
                                <img src={sandesh} alt="Sandesh Chat Application" />
                            </div>
                            <div className="title">
                                <strong>Sandesh - Chat Application</strong>
                            </div>
                            <div className="project_info">
                                <p>Sandesh is the online chat application to bring people together despite of geographical barriers. It provides some security features which have been added to give privacy protection to its users.</p>
                            </div>
                            <a href="https://sandeshchat.herokuapp.com/" target="blank"> <button className="glow-on-hover btn view_project">View</button></a>
                           
                        </div>
                        <div className="project glow-on-hover commercial">
                            <div className="project_image">
                                <img src={kitacademy} alt="Kit Academy" />
                            </div>
                            <div className="title">
                                <strong>KIT Academy</strong>
                            </div>
                            <div className="project_info">
                                <p>KIT college of Engineering started kit academy for 11th and 12th science. This website gives information about KIT academy, their staff. It also supports chatbot. It is one my commercial website.</p>
                            </div>
                            <a href="https://kitacademy.in/" target="blank"> <button className="glow-on-hover btn view_project">View</button></a>
                        </div>

                        
                        <div className="project glow-on-hover commercial">
                            <div className="project_image">
                                <img src={ivedics} alt="International Vedic Society" />
                            </div>
                            <div className="title">
                                <strong>IVedics</strong>
                            </div>
                            <div className="project_info">
                                <p>International Vedic Society is iskon like non-profit organization. It is one of my commercial project. Website is developed with modern interface providing information about ivedics. Website includes home page, about, contact, events and donation section.</p>
                            </div>
                            <a href="https://ivedics.org/" target="blank"> <button className="glow-on-hover btn view_project">View</button></a>
                        </div>
                        <div className="project glow-on-hover website">
                            <div className="project_image">
                                <img src={khatabook} alt="Khata Book" />
                            </div>
                            <div className="title">
                                <strong>Khata Book</strong>
                            </div>
                            <div className="project_info">
                                <p>Khata book is bill maintaining webapp where you can maintain bills regarding customers. It automatically calculates bill total and once you created khata it can be downloadable in pdf format.</p>
                            </div>
                            <a href="http://khatabook.ml/" target="blank"> <button className="glow-on-hover btn view_project">View</button></a>
                        </div>
                        <div className="project glow-on-hover website">
                            <div className="project_image">
                                <img src={uploadme} alt="uploadme" />
                            </div>
                            <div className="title">
                                <strong>uploadMe - File Sharing Platform</strong>
                            </div>
                            <div className="project_info">
                                <p>It is file and text sharing platform where user can upload file with any format and can retrieve it with a key generated.</p>
                            </div>
                            <a href="https://uploadme.ashwith.in/" target="blank"> <button className="glow-on-hover btn view_project">View</button></a>
                        </div>
                        <div className="project glow-on-hover website">
                            <div className="project_image">
                                <img src={shorturlme} alt="shorturlme" />
                            </div>
                            <div className="title">
                                <strong>tinyme - Free URL Shortener</strong>
                            </div>
                            <div className="project_info">
                                <p>It is url shortening platform where user can create custom url with custom names for Free.</p>
                            </div>
                            <a href="http://tinyme.ashwith.in/" target="blank"> <button className="glow-on-hover btn view_project">View</button></a>
                        </div>

                        <div className="project glow-on-hover website">
                            <div className="project_image">
                                <img src={goxo} alt="Goxo game" />
                            </div>
                            <div className="title">
                                <strong>GoXo - XO Game</strong>
                            </div>
                            <div className="project_info">
                                <p>It is tic tac toe game where people can connect to play. It is based on socket programming. People from different places can create or join provided with unique room name.</p>
                            </div>
                            <a href="http://goxo.herokuapp.com/" target="blank"> <button className="glow-on-hover btn view_project">View</button></a>
                        </div>

                        <div className="project glow-on-hover website">
                            <div className="project_image">
                                <img src={encrypt} alt="Encryption" />
                            </div>
                            <div className="title">
                                <strong>Encryption Techniques</strong>
                            </div>
                            <div className="project_info">
                                <p>This is a React Application that consists of various encryption techniques. I am still working on it. Currently Caesar Cipher encryption technique is available.</p>
                            </div>
                            <a href="https://encryptiontechniques.netlify.app/" target="blank"> <button className="glow-on-hover btn view_project">View</button></a>
                        </div>
                        <div className="project glow-on-hover website">
                            <div className="project_image">
                                <img src={cli} alt="Command Line App" />
                            </div>
                            <div className="title">
                                <strong>Phone Directory</strong>
                            </div>
                            <div className="project_info">
                                <p>This is the phone directory developed using C++. It consists of various features like adding, deleting, updating and displaying phone number. Records are accessed in form of linked list for dynamicity. Records are stored as text file and csv file.</p>
                            </div>
                            <a href="https://youtu.be/rPYk_bt2tJg" target="blank"> <button className="glow-on-hover btn view_project">View</button></a>
                        </div>
                        
                    </div>
                    
                </div>

            </section>
            
            <Link to="/contact"><button onClick={()=>moveAsidesMenu('contact_menu')} style={{marginBottom:"1rem"}}  className="glow-on-hover btn">Want Projects Done?</button></Link>
            <BackToTop/>
            
        </>
    );
}

export default Projects;