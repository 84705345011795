import React, { useEffect } from "react";
import './stylesheet/about.css';
import ashwithbhatt from './assets/Ashwith_Photo_NObg.png';
import Resume from './assets/Ashwith_Resume.pdf';
import { Link } from "react-router-dom";
import BackToTop from './backtotop';

function AboutMe(){
    window.speechSynthesis.cancel();
    window.scrollTo(0, 0);
    useEffect(()=>{
        let txt = "Web Developer.";
        let i =0;
        const type = document.getElementsByClassName('type')[0];
        typewriter();
        function typewriter(){
            if (i < txt.length) {
                type.innerHTML += txt.charAt(i);
                i++;
                setTimeout(typewriter, 250);
              }
        }
    },[]);
    
    let introSpeech = ()=>{
        console.log(window.speechSynthesis.getVoices());
        const intro = "An independent, self-motivated and Dedicated candidate with strong creative and analytical skills. Team player with an eye for detail. Quick learner looking forward for some amazing full time opportunities where I can contribute my best and get the best out of the same job where I aspire to work.";

        var msg = new SpeechSynthesisUtterance(intro);
        msg.voice = window.speechSynthesis.getVoices()[1];
        window.speechSynthesis.speak(msg);
    }
    return(
        <>

            <section className="about_main">
                <div className="about_title">
                    <p>About Me</p>
                </div>
                <div className="about_content">
                    <div className="about_left">
                        <p className="name_about">
                            Hi! I am <br /> <span> Ashwith Bhatt</span> <br />And I'm a <span className="type" style={{color:"#ffc20e"}}></span>
                        </p>
                        <p  onClick={introSpeech} className="about_intro glow-on-hover">
                        An independent, self-motivated and Dedicated candidate with strong creative and analytical skills. Team player with an eye for detail. Quick learner looking forward for some amazing full time opportunities where I can contribute my best and get the best out of the same job where I aspire to work. 🔊
                        </p>
                      <Link to="/contact"><button className="btn glow-on-hover">Get in Touch</button></Link>
                       <a href={Resume} download><button className="btn glow-on-hover">Download CV</button></a>
                    </div>

                    <div className="about_right">
                        <img src={ashwithbhatt} alt="Ashwith Bhatt" />
                        <div className="image_bg"></div>
                    </div>

                </div>
            </section>
            
            <BackToTop/>
        </>
    );
}

export default AboutMe;