import React from "react";
import './stylesheet/footer.css';
import {FaFacebook, FaLinkedin, FaGithub, FaInstagram} from 'react-icons/fa';

function Footer(){

    return(
        <>
            <footer>
                {/* <h3>Follow Me on</h3> */}
                <a  style={{color:"black"}}  target="blank" href="https://github.com/Ashwith2001"><i><FaGithub/></i></a> 
                <a  style={{color:"blue"}}  target="blank" href="https://www.facebook.com/ashwith.bhatt.9"><i><FaFacebook/></i></a> 
                <a  style={{color:"red"}}  target="blank" href="https://www.instagram.com/ashwith_bhatt_2001/"><i><FaInstagram/></i></a> 
                <a style={{color:"blue"}}  target="blank" href="https://www.linkedin.com/in/ashwith-bhatt-018251217"><i><FaLinkedin/></i> </a> 

                
                <p>@2022 Ashwith Bhatt</p>
            </footer>
        </>
    );
}
export default Footer;