import React from "react";
import '../src/stylesheet/home.css';
import personal_photo from './assets/Home_page_img.jpg';
import html_icon from './assets/html_icon.png';
import css_icon from './assets/css_icon.png';
import js_icon from './assets/js_icon.png';
import react_icon from './assets/react_icon.png';
import android_icon from './assets/android_icon.png';
import firebase_icon from './assets/firebase_icon.png';
import { Link } from "react-router-dom";
import BackToTop from './backtotop'

function Home(){
    
    window.speechSynthesis.cancel();
    window.scrollTo(0, 0);
    let introSpeech = ()=>{
        console.log(window.speechSynthesis.getVoices());
        const intro = "An independent, self-motivated and Dedicated candidate with strong creative and analytical skills. Team player with an eye for detail. Quick learner looking forward for some amazing full time opportunities where I can contribute my best and get the best out of the same job where I aspire to work.";

        var msg = new SpeechSynthesisUtterance(intro);
        msg.voice = window.speechSynthesis.getVoices()[1];
        window.speechSynthesis.speak(msg);   
         
    }
    return (
        <React.Fragment>
            <section className="home_page glow-on-hover">
                <div className="home-left">
                    <div className="title">
                        <p className="main">
                            Hi! I Am <br /> Ashwith Bhatt
                        </p>Developing User Interfaces and Websites <br /> for over <strong>3 years.</strong>
                        <p></p>
                    </div>

                    <div className="btns">
                       <Link to="/contact"> <button style={{marginLeft:"0", height:"50px"}} className="glow-on-hover btn">Hire Me</button></Link>
                       <Link to= {"/projects"}><button style={{marginLeft:"0", height:"50px"}} className="glow-on-hover btn">Projects</button></Link>
                    </div>

                    <div className="others">
                        <div className="projects">
                            <p>13  <br /><span>Projects</span> </p>
                        </div>
                        <div className="contact ">
                            
                            <p><span>Contact</span> <br /> ashwithbhatt2001@gmail.com</p>
                        </div>
                    </div>

                    <div className="statistics glow-on-hover">
                        <div className="left">
                            <p>Project <br /> Statistics 2022</p>
                        </div>
                        <div className="right">
                            <p>Websites Developed &nbsp; &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;11</p>
                            <p>Mobile Apps Developed &nbsp; 2</p>
                        </div>
                    </div>
                </div>

                <div className="home-right">
                    <div className="personal_img">
                        <img src={personal_photo} alt="Ashwith Bhatt" />
                    </div>
                    <div onClick={introSpeech} className="intro glow-on-hover">
                    An independent, self-motivated and Dedicated candidate with strong creative and analytical skills. Team player with an eye for detail. Quick learner looking forward for some amazing full time opportunities where I can contribute my best and get the best out of the same job where I aspire to work. 🔊
                    </div>
                    <img className="html_icon_class" id="html_icon" src={html_icon} alt="Html Icon" />
                    <img id="css_icon" src={css_icon} alt="Css Icon" />
                    <img id="js_icon" src={js_icon} alt="JS Icon" />
                    <img id="react_icon" src={react_icon} alt="React Icon" />
                    <img id="android_icon" src={android_icon} alt="React Icon" />
                    <img id="firebase_icon" src={firebase_icon} alt="React Icon" />
                </div>
            </section>
            <BackToTop/>
        </React.Fragment>
    );
}

export default Home;