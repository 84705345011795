import React from 'react';
import {
    BrowserRouter as Router,
    Routes,
    Route,
  } from "react-router-dom";

import NavigationBar from './navigationbar';
import Skills from './skills';
import Home from './home';
import Footer from './footer';
import Projects from './projects';
import AboutMe from './about';
import Contact from './contact';

function App(){

    return(
        <Router>
            <NavigationBar/>
            <Routes>
                <Route path="/" element={<Home/>}/>
                <Route path="/skills" element={<Skills/>}/>
                <Route path='/projects' element={<Projects/>}></Route>
                <Route path='/aboutme' element={<AboutMe/>}></Route>
                <Route path='/contact' element={<Contact/>}></Route>
            </Routes>
            <Footer/>
        </Router>

    );
}
export default App;