import React, { useEffect } from "react";
import './stylesheet/contact.css';
import BackToTop from './backtotop';

function Contact(){
    window.speechSynthesis.cancel();
    window.scrollTo(0, 0);
    useEffect(()=>{
        let txt = "ashwithbhatt2001@gmail.com";
        let i =0;
        const type = document.getElementsByClassName('typecontact')[0];
        typewriter();
        function typewriter(){
            if (i < txt.length) {
                type.innerHTML += txt.charAt(i);
                i++;
                setTimeout(typewriter, 100);
              }
        }
    },[]);
    return (
        <>
            <section className="contact_main">
                {/* <div className="contact_title">
                    <p>Contact Me</p>
                </div> */}

                <div className="contact_content">
                    
                    <div className="contact_left   glow-on-hover">
                        <form action="https://formspree.io/f/moqbjrge" method="POST" className="form_details">
                            <div className="contact_name">

                                <div className="sub_name">
                                    <span>First Name <span>*</span></span>
                                    <input type="text" name="first name" required/>
                                </div>
                                <div className="sub_name">
                                    <span>Last Name  <span>*</span></span>
                                    <input type="text" name="last name" required/>
                                </div>

                            </div>
                            <div className="sub_name">
                                <span>Email  <span>*</span></span>
                                <input type="email" name="email" required/>
                            </div>
                            
                            <div className="sub_name">
                                <span>Message  <span>*</span></span>
                                <textarea name="message" cols="30" rows="10" required></textarea>
                            </div>
                            <button type="submit" className="glow-on-hover btn">Send Message</button>
                        </form>
                        
                    </div>

                    <div className="contact_right">
                        <div className="contact_right_title">
                            <p>How Can I Help You?</p>
                        </div>
                        <div className="contact_info">
                            <p>For all enquiries, mail me using the form provided <br /> or use my email.</p>
                        </div>
                        <div className="other_contact_info">
                            <p>Want Projects Done?</p>
                            <span>Pick topic, provide requirements and features you want about the topic and send it using the form provided. </span>
                        </div>

                        <div className="services_provided">
                            <div className="contact_frontend glow-on-hover">
                                <p>FrontEnd</p>
                                <span>Get amazing UI done which are reliable and cost effective.</span>
                            </div>
                            <div className="contact_backend glow-on-hover">
                                <p>Backend</p>
                                <span>Get reliable Backend done with SQL and NoSQL Databases.</span>
                            </div>
                        </div>
                        <div className="icons_contact glow-on-hover">
                            <p>&#9993; Email: <span className="typecontact"></span></p>
                        </div>
                    </div>
                </div>
            </section>
            
            <BackToTop/>
        </>
    );
}

export default Contact;