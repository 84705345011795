import React from "react";
import "./stylesheet/skills.css";
import {
  FaImage,
} from "react-icons/fa";
import tcs_codevita from "./assets/certificates/tcs_codevita.PNG";
import aws_aiml from "./assets/certificates/aws_aiml.PNG";
import palo_alto from "./assets/certificates/palo_alto.PNG";
import pbl_comp from "./assets/certificates/pbl_comp.PNG";
import react_js from "./assets/certificates/react_js.PNG";
import BackToTop from './backtotop';
import { SkillsSchema } from "./utilities/skills-schema";

function Skills() {
  window.scrollTo(0, 0);
  window.speechSynthesis.cancel();
  let closeBtn = () => {
    const image_display = document.getElementsByClassName('image_display')[0];
    image_display.style.display = 'none';
    document.getElementsByClassName('skills_main')[0].style.opacity = '1';

  }

  let onClickListener = (para) => {
    const image_display_id = document.getElementById('image_display_id');
    const image_display = document.getElementsByClassName('image_display')[0];
    image_display_id.src = para;
    image_display.style.display = 'block';
    document.getElementsByClassName('skills_main')[0].style.opacity = '0.4';
    console.log(document.getElementsByClassName('skills_main')[0].style.opacity);
  }


  return (
    <>
      <section id="skills_section" className="skills_main">
        <div className="title">
          <p className="skills_title">Skills {"&"} Certifications</p>
        </div>

        <div className="skills">
          {
            Object.entries(SkillsSchema).map(([k, v], ki) => {
              return <>
                <div key={`skill-parent-${ki}`} className="skill_sub_main glow-on-hover">
                  <div key={`skill-title-${ki}`} className="skill_title">
                    <p>{k}</p>
                  </div>
                  <div key={`skill-content-${ki}`} className="frontend">
                    {
                      v.sort((a, b) => b.skill - a.skill).map((skill, si) => {
                        return <>
                          <div key={`skill-${si}`} className="Skill">
                            <div className="first">
                              <p>
                                <span style={{ color: skill.color, fontSize: "25px" }}>
                                  {skill.icon}
                                </span>{" "}
                                {skill.name}
                              </p>
                              <p>{skill.skill}%</p>
                            </div>
                            <div className="customLevel">
                              <p style={{
                                "--width": `${skill.skill}%`,
                                width: `${skill.skill}%`, animation: `customLoading 1.5s forwards`,
                              }}></p>
                            </div>
                          </div>
                        </>
                      })
                    }
                  </div>
                </div >
              </>
            })
          }
        </div>

        <div style={{ marginTop: "5%" }} className="title">
          <p className="skills_title">Certifications</p>
        </div>

        <div id="certificate_scroll" className="certificates_main">
          <div className="certificate  glow-on-hover">
            <div onClick={() => { onClickListener(tcs_codevita) }} className="left">
              <img src={tcs_codevita} alt="" />
              <div className="cover">
                <i><FaImage /></i>
              </div>
            </div>

            <div className="right">
              <div className="cert_title">
                <p>
                  TCS Codevita Global Rank <strong>3822</strong>
                </p>
              </div>
              <div className="info">
                <p>
                  I secured a global rank of #3822 in TCS Codevita 2022. TCS Codevita is organized by TCS every year in which any graduate students can participate and give their best. Coders from all over world participant in this competition.
                </p>
              </div>
            </div>
          </div>
          <div className="certificate  glow-on-hover">
            <div onClick={() => { onClickListener(aws_aiml) }} className="left">
              <img src={aws_aiml} alt="" />
              <div className="cover">
                <i><FaImage /></i>
              </div>
            </div>

            <div className="right">
              <div className="cert_title">
                <p>Aws AI Ml Virtual Internship</p>
              </div>
              <div className="info">
                <p>
                  I completed AWS AI-ML internship offered by AICTE in OCT-DEC 2021. Internship included hands-on labs for creating server on Amazon Web Services Platform.
                </p>
              </div>
            </div>
          </div>
          <div className="certificate  glow-on-hover">
            <div onClick={() => { onClickListener(palo_alto) }} className="left">
              <img src={palo_alto} alt="" />
              <div className="cover">
                <i><FaImage /></i>
              </div>
            </div>

            <div className="right">
              <div className="cert_title">
                <p>PaloAlto CyberSecurity Virtual Internship</p>
              </div>
              <div className="info">
                <p>
                  I completed Palo Alto internship offered by AICTE in MAr-MAY 2022. Internship included well prepared presentation about various concepts of Cyber Security, viruses, firewall and their applications. Assesment based learning helped a lot to understand concepts.
                </p>
              </div>
            </div>
          </div>
          <div className="certificate  glow-on-hover">
            <div onClick={() => { onClickListener(react_js) }} className="left">
              <img src={react_js} alt="" />
              <div className="cover">
                <i><FaImage /></i>
              </div>
            </div>

            <div className="right">
              <div className="cert_title">
                <p>React JS Course Completion</p>
              </div>
              <div className="info">
                <p>
                  I completed React Js Course on Udemy. I learnt various concepts in React from basic to advance. Major topics include react components, react hooks, react routers and many more.
                </p>
              </div>
            </div>
          </div>
          <div className="certificate  glow-on-hover">
            <div onClick={() => { onClickListener(pbl_comp) }} className="left">
              <img src={pbl_comp} alt="" />
              <div className="cover">
                <i><FaImage /></i>
              </div>
            </div>

            <div className="right">
              <div className="cert_title">
                <p>PBL Competition Runner Up</p>
              </div>
              <div className="info">
                <p>
                  KIT College organizes Project Based learning Competition every semester. In 6th semester PBL competion my team secured 2nd rank. Our project was Savaari-Bus Tracking Android Application.
                </p>
              </div>
            </div>
          </div>
        </div>

      </section>
      <div className="image_display">
        <p onClick={closeBtn}>×</p>
        <img id="image_display_id" src={palo_alto} alt="" />
      </div>

      <BackToTop />
    </>
  );
}
export default Skills;
