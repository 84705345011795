import React, { useEffect } from "react";
import logo from './assets/ashwithbhatt-white.png';
import logo_black from './assets/ashwithbhatt-black.png';
import './stylesheet/navigation.css';
import { FaInstagram, FaLinkedin, FaGithub, FaBars } from 'react-icons/fa';
import { Link } from 'react-router-dom';
function NavigationBar() {

    let openSideBar = (openClose) => {
        const side_menu = document.getElementsByClassName("side-menu")[0];
        if (openClose) {
            side_menu.style.animation = "side_bar_animation_open forwards 0.5s";
        }
        else
            side_menu.style.animation = "side_bar_animation_close forwards 0.5s";
    }
    const urlData = window.location.href;

    let moveAsides = (para) => {
        const side_menu = document.getElementsByClassName("side-menu")[0];
        side_menu.style.animation = "side_bar_animation_close forwards 1s";
        document.getElementById('side_home').classList.remove('curr_page_on');
        document.getElementById('side_skills').classList.remove('curr_page_on');
        document.getElementById('side_projects').classList.remove('curr_page_on');
        document.getElementById('side_aboutme').classList.remove('curr_page_on');
        document.getElementById('side_contact').classList.remove('curr_page_on');
        document.getElementById(para).classList.add('curr_page_on');
    }

    let moveAsidesMenu = (para = "home_menu") => {
        document.getElementById('home_menu').classList.remove('curr_page_on_menu');
        document.getElementById('skills_menu').classList.remove('curr_page_on_menu');
        document.getElementById('projects_menu').classList.remove('curr_page_on_menu');
        document.getElementById('aboutme_menu').classList.remove('curr_page_on_menu');
        document.getElementById('contact_menu').classList.remove('curr_page_on_menu');
        // document.getElementById('side_contact').classList.remove('curr_page_on_menu');
        document.getElementById(para).classList.add('curr_page_on_menu');
    }
    useEffect(() => {
        if (urlData.split('/')[urlData.split('/').length - 1] !== "") {
            moveAsidesMenu(urlData.split('/')[urlData.split('/').length - 1] + "_menu");
            if (window.screen.availWidth <= 805)
                moveAsides("side_" + urlData.split('/')[urlData.split('/').length - 1]);
        }
        else {
            moveAsidesMenu("home_menu");
            if (window.screen.availWidth <= 805)
                moveAsides("side_home");
        }
        let txt = "Use Tinyme to generate short urls with custom name for free!!";
        let i = 0;
        const type = document.getElementById('type-notice')
        type.innerHTML = "";
        typewriter();
        function typewriter() {
            if (i < txt.length) {
                type.innerHTML += txt.charAt(i);
                i++;
                setTimeout(typewriter, 100);
            }
            else {
                let node = document.createElement("a");
                node.innerHTML = " here.";
                node.href = "https://tinyme.ashwith.in";
                node.style = "text-decoration:none; color: black;";
                node.target = "_blank"
                type.appendChild(node);
            }
        }
    });

    return (
        <>
            <p id="type-notice" className="notices"></p>

            <nav>
                <div className="logo">
                    <Link onClick={() => moveAsidesMenu('home_menu')} to="/"> <img src={logo} alt="Ashwith Bhatt" /></Link>
                </div>
                <div className="nav-bar">
                    <ul className="nav-bar-content">
                        <li ><Link onClick={() => moveAsidesMenu('home_menu')} id="home_menu" className="a curr_page_on_menu" to="/">Home</Link></li>
                        <li><Link onClick={() => moveAsidesMenu('skills_menu')} id="skills_menu" className="a" to="/skills">Skills</Link></li>
                        <li><Link onClick={() => moveAsidesMenu('projects_menu')} id="projects_menu" className="a" to="/projects">Projects</Link></li>
                        <li style={{ zIndex: "999" }}><Link onClick={() => moveAsidesMenu('aboutme_menu')} id="aboutme_menu" className="a" to="/aboutme">About</Link></li>
                    </ul>
                </div>
                <div className="left-item">
                    <Link id="contact_menu" onClick={() => moveAsidesMenu('contact_menu')} to="/contact"><button className="glow-on-hover btn">Let's Chat</button></Link>
                </div>

                <div className="bars-icon">
                    <FaBars onClick={() => openSideBar(true)} />
                </div>

                {/* side bar */}
                <div className="side-menu">
                    <div className="close-bt">
                        <p onClick={() => openSideBar(false)}>✕</p>
                    </div>
                    <div className="side-logo">
                        <img src={logo_black} alt="Ashwith Bhatt" />
                    </div>
                    <div className="side-bar">
                        <ul className="side-bar-content">
                            <li ><Link onClick={() => moveAsides('side_home')} id="side_home" className="a curr_page_on" to="/">Home</Link></li>
                            <li><Link onClick={() => moveAsides('side_skills')} id="side_skills" className="a" to="/skills">Skills</Link></li>
                            <li><Link onClick={() => moveAsides('side_projects')} id="side_projects" className="a" to="/projects">Projects</Link></li>
                            <li><Link onClick={() => moveAsides('side_aboutme')} id="side_aboutme" className="a" to="/aboutme">About</Link></li>
                            <li><Link onClick={() => moveAsides('side_contact')} id="side_contact" className="a" to="/contact">Contact</Link></li>
                        </ul>
                    </div>
                    <div className="social-media">
                        <a href="https://www.linkedin.com/in/ashwith-bhatt-018251217" target="blank"> <FaLinkedin /></a>
                        <a href="https://github.com/Ashwith2001/" target="blank"> <FaGithub /></a>
                        <a href="https://www.instagram.com/ashwith_bhatt_2001/" target="blank"> <FaInstagram /></a>

                    </div>
                </div>
            </nav>
        </>
    );
}

export default NavigationBar;